<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img
                src="../assets/logo_color.png"
                max-height="250"
                max-width="220"
              ></v-img>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#29bb89">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <h3>{{ Header }}</h3>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <v-container grid-list-xs>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      NavigatorDefault: [
        {
          key: "menu1",
          text: "จัดการหมวดหมู่สินค้า",
          path: "/ManageCategory",
          name: "ManageCategory",
          icon: "menu",
        },
        {
          key: "ManageType",
          text: "จัดการประเภทสินค้า",
          path: "/ManageType",
          name: "ManageType",
          icon: "unordered-list",
        },
        {
          key: "ManageProduct",
          text: "จัดการสินค้า",
          path: "/ManageProduct",
          name: "ManageProduct",
          icon: "shopping",
        },
        {
          key: "menu4",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "menu5",
          text: "จัดการออเดอร์",
          path: "/ManageOrder",
          name: "ManageOrder",
          icon: "user",
        },
      ],

      Navigator: [],
    };
  },
  created() {
    // this.Navigator = this.NavigatorDefault;
    var checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    console.log("user", checkuser);
    this.username = checkuser.name;
    console.log("username", this.username);

    this.Navigator = this.NavigatorDefault;

    this.CheckHeader();
  },
  methods: {
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 89vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
