<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-row justify="center" align="center">
              <v-img
                src="@/assets/logo_color.png"
                max-width="400px"
                max-height="300px"
              />
              <!-- <h3 style="font-weight: 800; font-size: 36px" class="mt-2">
                Tax Local ทม. อรัญญิก
              </h3> -->
            </v-row>
            <v-card class="elevation-12 mt-12">
              <v-toolbar color="#29bb89" style="color: white">
                <p class="font-weight-light mt-4" style="font-size: 20px">
                  เข้าสู่ระบบ
                </p>
              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="loginfrom"
                  v-model="loginfrom"
                  :lazy-validation="lazy"
                >
                  <v-text-field
                    placeholder="login"
                    type="text"
                    outlined
                    v-model="username"
                    :rules="Rules.empty"
                    @keyup.enter="login()"
                  ></v-text-field>
                  <v-text-field
                    placeholder="password"
                    type="password"
                    outlined
                    v-model="password"
                    :rules="Rules.empty"
                    @keyup.enter="login()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#29bb89"
                  style="color: white"
                  class="font-weight-light"
                  @click="login()"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { Encode } from "@/services";
export default {
  data() {
    return {
      lazy: "",
      loginfrom: "",
      Rules: {
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      username: "",
      password: "",
    };
  },
  methods: {
    resetValidation() {
      this.$refs.loginfrom.resetValidation();
    },
    async login() {
      const login = {
        username: this.username,
        password: this.password,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/login`,
        login
      );
      console.log("login", response.data.data);

      if (response.data.response_status === "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });

        // localStorage.setItem("user", Encode.encode(response.data.data));
        localStorage.setItem("user", Encode.encode(response.data.data));

        await this.$router.push("/ManageCategory");
      } else if (this.username == "" || this.password == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอก username password",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: response.data.message,
        });
      }
    },
  },
};
</script>

